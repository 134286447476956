export enum EnumMyCourseFilter {
  NEW = 'NEW',
  BEST = 'BEST',
  MAX = 'MAX',
}

export enum EnumSystemCode {
  TWO_VISION = 'T',
  VISION_PLUS = 'VP',
  VISION = 'V',
  REAL = 'R',
  GDR = 'G',
  GDR_PLUS = 'GP',
}

export enum EnumSystemName {
  TWO_VISION = 'TWOVISION',
  VISION_PLUS = 'VISION Plus',
  VISION = 'VISION',
  REAL = 'REAL',
  GDR = 'GDR',
  GDR_PLUS = 'GDR Plus',
}
