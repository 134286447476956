import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IGDRDrivingClubResponse } from 'src/requests/api/record/prop-state.type';
import DrivingItem from 'src/view/pages/record/commons/DrivingItem';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import ListGroupMonth from 'src/view/pages/record/commons/ListGroupMonth';
import { IOffsetListRecord, records, recordsAction } from 'src/store/records';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import { useScrollToOldPosition } from 'src/hooks/scroll-to-old';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';

interface IRecordGdrDrivingRangeProps {
  offsetListRecord: IOffsetListRecord;
  setOffset: (offsetListRecord: IOffsetListRecord) => void;
  dataGdrDriving: IObjectData<IGDRDrivingClubResponse[][]>;
  getGdrDrivingInformation: (options: { isLoadingMore?: boolean; isRefresh?: boolean }) => Promise<void>;
}

function RecordGdrDrivingRange(props: IRecordGdrDrivingRangeProps): JSX.Element {
  const translate = useTranslation().t;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const firstLoad = props.dataGdrDriving.loaded === 0;

  useScrollToOldPosition(!firstLoad, props.offsetListRecord.driving, (value: number) => {
    props.setOffset({
      ...props.offsetListRecord,
      driving: value,
    });
  });

  useEffect(() => {
    if (firstLoad) {
      fetchData();
    }
  }, []);

  const fetchData = (isLoadingMore?: boolean) => {
    props.getGdrDrivingInformation({ isLoadingMore });
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await props.getGdrDrivingInformation({ isRefresh: true });
    setIsRefreshing(false);
  };

  return (
    <>
      {/* // @TODO Can Rollback
        <div
          className="bg-bg py-[12px] px-[24px] gz-text-xs text-sub-body list-default"
          dangerouslySetInnerHTML={{
            __html: translate('pages.record.type.GDR.driving_range.DESCRIPTION'),
          }}
        />
      */}
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={!!props.dataGdrDriving?.loaded && !firstLoad && !isRefreshing}
        showFooter
      >
        {firstLoad ? (
          <div className="flex items-center justify-center py-[24px]">
            <LoadingIndicator className="h-[16px] w-[16px] self-center" />
          </div>
        ) : (
          <>
            {props.dataGdrDriving?.keySort?.length > 0 ? (
              <div className="p-[24px]">
                <ListGroupMonth
                  dataObject={props.dataGdrDriving}
                  fetchData={fetchData}
                  lastPage={props.dataGdrDriving.lastPage}
                  loading={props.dataGdrDriving.loading}
                  fieldDate="statDate"
                >
                  <DrivingItem />
                </ListGroupMonth>
              </div>
            ) : (
              <NotFoundItem className="pb-[80px]" text={translate('pages.record.message.NO_ROUND_RECORD')} />
            )}
          </>
        )}
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  dataGdrDriving: state.recordsReducer.dataGdrDriving,
  offsetListRecord: state.recordsReducer.offsetListRecord,
});
const mapDispatchToProps = {
  setOffset: records.setOffset,
  getGdrDrivingInformation: recordsAction.getGdrDrivingInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordGdrDrivingRange);
