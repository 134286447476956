import { MAX_STANDARD_HOLE_COUNT } from 'src/constants/const';
import { EnumPlayMode, EnumScoreCardMatch } from 'src/constants/enum';
import { getPlayMode } from 'src/services/gdr-and-gs';
import { twMerge } from 'tailwind-merge';

export enum EnumRoundScoreType {
  RECORD,
  RECORD_DETAIL,
}

interface IRoundScoreProps {
  className?: string;
  classText?: string;
  playMode: number;
  playModeSub?: number;
  holeCnt?: number;
  hitCnt?: number;
  totalScore?: number;
  matchTotal?: number;
  baseScore?: string | number;
  scorecard?: boolean;
  isAS?: boolean;
  type?: EnumRoundScoreType;
}

function RoundScore({
  className,
  classText,
  playMode,
  playModeSub,
  holeCnt,
  hitCnt,
  totalScore,
  matchTotal,
  baseScore,
  isAS = false,
  type = EnumRoundScoreType.RECORD,
  scorecard = false,
}: IRoundScoreProps): JSX.Element {
  const playModeInfo = getPlayMode(playMode, playModeSub);
  const isScoreCardType = scorecard;
  const isRecordDetailType = type === EnumRoundScoreType.RECORD_DETAIL;

  const renderRecordScore = () => {
    if (
      playModeInfo === EnumPlayMode.MATCH ||
      playModeInfo === EnumPlayMode.BEST_SHOT_MATCH ||
      playModeInfo === EnumPlayMode.BEST_BALL_MATCH ||
      playModeInfo === EnumPlayMode.SKINS
    ) {
      return renderMatchPlayScore(matchTotal ?? 0);
    }

    return renderRoundTotalScore(hitCnt ?? 0, totalScore ?? 0);
  };

  const renderRecordDetailScore = () => {
    if (
      playModeInfo === EnumPlayMode.BEST_BALL_STROKE ||
      playModeInfo === EnumPlayMode.BEST_SHOT_STROKE ||
      playModeInfo === EnumPlayMode.FOURSOMES
    ) {
      return renderRoundTotalScore(hitCnt ?? 0, totalScore ?? 0);
    }
    if (
      playModeInfo === EnumPlayMode.MATCH ||
      playModeInfo === EnumPlayMode.BEST_BALL_MATCH ||
      playModeInfo === EnumPlayMode.BEST_SHOT_MATCH
    ) {
      return renderMatchPlayScore(matchTotal ?? 0);
    }
    if (playModeInfo === EnumPlayMode.SKINS) {
      if (!baseScore) return <></>;
      const base = baseScore
        ?.toString()
        .split(/(\d+)/)
        .filter((el) => el !== '');
      if (!base || !base[1]) return <></>;
      const affix = base[1];
      const matchScore = Number(base[0]);
      const match = affix.toLocaleUpperCase() === EnumScoreCardMatch.DOWN ? -matchScore : matchScore;
      return renderMatchPlayScore(match);
    }
    return renderRoundTotalScore(hitCnt ?? 0, totalScore ?? 0);
  };

  const renderRoundTotalScore = (roundTotalScore: number, roundScore: number) => {
    return (
      <div className={twMerge('ml-[16px] flex shrink-0 flex-col items-center', className)}>
        <div
          className={twMerge(
            'gz-text-xl font-bold',
            holeCnt && holeCnt === MAX_STANDARD_HOLE_COUNT ? 'text-primary-75' : 'text-disable',
            isScoreCardType ? 'text-gz-white' : '',
            classText,
          )}
        >
          {roundTotalScore}
        </div>
        <div className={twMerge('gz-text-xsm', isScoreCardType ? 'mt-[8px]' : 'mt-[4px]', classText)}>
          ({roundScore > 0 ? `+${roundScore}` : roundScore})
        </div>
      </div>
    );
  };

  const renderMatchPlayScore = (total: number) => {
    return (
      <div
        className={twMerge(
          'gz-text-xl self-start font-bold',
          isScoreCardType ? 'flex flex-col items-center' : '',
          holeCnt && holeCnt === MAX_STANDARD_HOLE_COUNT
            ? 'bg-record-score-gradient text-orange-40'
            : 'bg-gray-5 text-disable',
          className,
          classText,
        )}
      >
        {(isAS && playModeInfo === EnumPlayMode.SKINS) || (playModeInfo !== EnumPlayMode.SKINS && total === 0) ? (
          EnumScoreCardMatch.AS
        ) : (
          <>
            <span>{Math.abs(total)}</span>
            <span className={twMerge(isScoreCardType ? 'mt-[8px]' : '')}>
              {total >= 0 ? EnumScoreCardMatch.UP : EnumScoreCardMatch.DOWN}
            </span>
          </>
        )}
      </div>
    );
  };

  if (isRecordDetailType) {
    return renderRecordDetailScore();
  }
  return renderRecordScore();
}

export default RoundScore;
