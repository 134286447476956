import React from 'react';
import RoundRecordInformation from 'src/view/pages/record/commons/RoundRecordInformation';
import { pathConstants } from 'src/constants/const';
import { IRecordScore } from 'src/models';
import { Link } from 'react-router-dom';

interface IScoreItemProps {
  item?: IRecordScore;
}
const ScoreItem: React.FC<IScoreItemProps> = React.memo(({ item }) => {
  return (
    <>
      {item && (
        <Link to={pathConstants.RECORD_DETAIL_GS(item.pgCode?.toString())}>
          <RoundRecordInformation
            recordScore={{
              ccName: item.ccName,
              playMode: item.playMode,
              playModeSub: item.playModeSub,
              playDifficulty: item.playDifficulty,
              playType: item.playType,
              systemVersion: item.systemVersion,
              holeCnt: item.holeCnt,
              totalHoleCnt: item.totalHoleCnt,
              score: item.score,
              tags: item.tags,
              tagsCnt: item.tagsCnt,
              totalScore: item.totalScore,
              partnerList: item.partnerList,
              partnerCnt: item.partnerCnt,
              matchTotal: item.matchTotal,
              gameMode: item.gameMode,
            }}
          />
        </Link>
      )}
    </>
  );
});

export default ScoreItem;
