import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { isDesktop } from 'react-device-detect';
import { ITypeLayout } from 'src/view/pages/record/commons/NasmoList';
import InfiniteScrollWrapper from 'src/view/commons/customs/InfiniteScroll';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';
import { useScrollToOldPosition } from 'src/hooks/scroll-to-old';
import { IOffsetListRecord, ITmSwingVideosValues, records, recordsAction } from 'src/store/records';
import { ITmSwingVideoInfoResponse } from 'src/requests/api/record/prop-state.type';
import NasmoList from 'src/view/pages/record/detail/training-mode/commons/NasmoList';
import VideoDetail from 'src/view/pages/record/detail/training-mode/commons/VideoDetail';
import { Dayjs } from 'src/services/datetime';
import NoTrainingModeData from 'src/view/pages/record/about/training-mode/commons/NoData';
import { firebaseInstance } from 'src/init-firebase';
import { eventName } from 'src/constants/const/firebase.constants';

interface IProps {
  offsetListRecord: IOffsetListRecord;
  dataTmSwingVideo: ITmSwingVideosValues;
  getTmSwingVideo: (options: { isLoadingMore?: boolean; isRefresh?: boolean }) => Promise<void>;
  updateTmSwingVideoDetail: (videoDetail?: ITmSwingVideoInfoResponse) => void;
  setOffset: (offsetListRecord: IOffsetListRecord) => void;
}
function TrainingModeSwingVideo({
  offsetListRecord,
  dataTmSwingVideo,
  getTmSwingVideo,
  updateTmSwingVideoDetail,
  setOffset,
}: IProps): JSX.Element {
  const translate = useTranslation().t;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const firstLoad = dataTmSwingVideo.loaded === 0;
  const videoDetail = dataTmSwingVideo.videoDetail;

  useScrollToOldPosition(!firstLoad, offsetListRecord?.tmSwingVideo, (value: number) => {
    setOffset({
      ...offsetListRecord,
      tmSwingVideo: value,
    });
  });

  useEffect(() => {
    if (firstLoad) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!dataTmSwingVideo.videoDetail && dataTmSwingVideo.data.length > 0) {
      updateTmSwingVideoDetail(dataTmSwingVideo.data[0].swingVideos[0]);
    }
  }, [dataTmSwingVideo.data]);

  const fetchData = (isLoadingMore?: boolean) => {
    getTmSwingVideo({ isLoadingMore });
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await getTmSwingVideo({ isRefresh: true });
    setIsRefreshing(false);
  };

  const handleChooseVideo = (nasmoDetail?: ITmSwingVideoInfoResponse) => {
    if (!nasmoDetail) {
      updateTmSwingVideoDetail();
      return;
    }
    updateTmSwingVideoDetail(nasmoDetail);
  };

  return (
    <>
      {!!videoDetail && (
        <VideoDetail
          className="sticky top-0 z-10"
          videoDetail={{ ...videoDetail }}
          classSideWrapper="px-[24px] bg-gz-white"
          headerHeight={isDesktop ? 0 : 64}
          expanded
          shortData
          hasAIAnalysis
          onRequestAIClick={() => firebaseInstance.trackEvent(eventName.ACTIVITY_MYPRACTICE_VIDEO_REQUESTAI)}
          onViewAIResultClick={() => firebaseInstance.trackEvent(eventName.ACTIVITY_MYPRACTICE_VIDEO_VIEWAI)}
        />
      )}
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={!!dataTmSwingVideo.loaded && dataTmSwingVideo.loaded > 0 && !isRefreshing}
        showFooter
      >
        <>
          {dataTmSwingVideo.loaded && dataTmSwingVideo.loaded > 0 && dataTmSwingVideo.data.length === 0 ? (
            <NoTrainingModeData />
          ) : (
            <InfiniteScrollWrapper
              loadMore={fetchData}
              isLastPage={dataTmSwingVideo.lastPage}
              loading={dataTmSwingVideo.loading || isRefreshing}
            >
              <>
                {dataTmSwingVideo.data.map((data, index) => {
                  return (
                    <div
                      key={`month-group-${index}`}
                      className={twMerge(
                        'p-[24px] first:border-t-[8px] [&:not(:last-child)]:border-b-[8px] [&:not(:last-child)]:border-gray-10',
                      )}
                    >
                      <h3 className="gz-text-xl mb-[16px] font-bold">
                        {Dayjs.formatDate(data.swingVideos[0].tsTimeStart, { translate })}
                      </h3>
                      <NasmoList
                        hideHole
                        absoluteClubName={false}
                        titleCenter={false}
                        classClubName="text-text"
                        classActiveClubName="text-gz-primary"
                        listNasmo={data.swingVideos}
                        distanceDecimal={1}
                        typeLayout={ITypeLayout.LIST}
                        chooseVideo={handleChooseVideo}
                        defaultActive={videoDetail?.urlVideo}
                      />
                    </div>
                  );
                })}
              </>
            </InfiniteScrollWrapper>
          )}
        </>
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  offsetListRecord: state.recordsReducer.offsetListRecord,
  dataTmSwingVideo: state.recordsReducer.dataTmSwingVideo,
});
const mapDispatchToProps = {
  getTmSwingVideo: recordsAction.getTmSwingVideo,
  updateTmSwingVideoDetail: recordsAction.updateTmSwingVideoDetail,
  setOffset: records.setOffset,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingModeSwingVideo);
