import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { noImageSwingFeedLight } from 'src/assets/images';
import { IconAngleRight } from 'src/assets/svg';
import { pathConstants } from 'src/constants/const';
import { eventName } from 'src/constants/const/firebase.constants';
import { EnumAIRecordType, EnumRequestCode } from 'src/constants/enum';
import { useScrollToOldPosition } from 'src/hooks/scroll-to-old';
import { useEventFirebase } from 'src/hooks/use-event-firebase';
import { firebaseInstance } from 'src/init-firebase';
import { NotificationType } from 'src/models';
import { IGsNasmoClubDetail } from 'src/models/nasmo';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import { GSrecord } from 'src/requests/api/record/GS';
import { IGsNasmoClubResponse } from 'src/requests/api/record/prop-state.type';
import { DateTime } from 'src/services/datetime';
import { deleteFeedAfterDeletingSwingVideo } from 'src/services/feed';
import { mappingClubCodeAndName } from 'src/services/gdr-and-gs';
import { history } from 'src/services/history';
import { notificationActions } from 'src/store/notification';
import { IGsSwingVideoValues, IOffsetListRecord, records, recordsAction } from 'src/store/records';
import InfiniteScrollWrapper from 'src/view/commons/customs/InfiniteScroll';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';
import NasmoList, { ITypeLayout } from 'src/view/pages/record/commons/NasmoList';
import PlayMode from 'src/view/pages/record/commons/PlayMode';
import VideoRegion, { IVideoDetail } from 'src/view/pages/record/commons/VideoRegion';

interface IRecordGsSwingVideoProps {
  offsetListRecord: IOffsetListRecord;
  setOffset: (offsetListRecord: IOffsetListRecord) => void;
  dataGsSwingVideo: IObjectData<IGsSwingVideoValues>;
  getGsSwingVideo: (options: { isLoadingMore?: boolean; isRefresh?: boolean }) => Promise<void>;
  updateGsSwingVideoDetail: (videoDetail?: IVideoDetail) => void;
  addNotification: (message: string, type: NotificationType) => void;
}

function RecordGsSwingVideo({
  offsetListRecord,
  dataGsSwingVideo,
  setOffset,
  getGsSwingVideo,
  updateGsSwingVideoDetail,
  addNotification,
}: IRecordGsSwingVideoProps): JSX.Element {
  const translate = useTranslation().t;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { isMenucard } = useEventFirebase();
  const firstLoad = dataGsSwingVideo.loaded === 0;
  const videoDetail = dataGsSwingVideo.values.videoDetail;
  const videoIdDefault = dataGsSwingVideo.values.videoDetail?.nasmoId ?? -1;

  useScrollToOldPosition(!firstLoad, offsetListRecord.gsSwingVideo, (value: number) => {
    setOffset({
      ...offsetListRecord,
      gsSwingVideo: value,
    });
  });

  useEffect(() => {
    if (firstLoad) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (firstLoad || !videoDetail) {
      chooseFirstVideo(dataGsSwingVideo.values.data);
    }
  }, [dataGsSwingVideo.values]);

  const fetchData = (isLoadingMore?: boolean) => {
    getGsSwingVideo({ isLoadingMore });
  };

  const handleChooseVideo = (nasmoDetail?: IGsNasmoClubDetail, gameNo?: number) => {
    if (!nasmoDetail || !gameNo) {
      updateGsSwingVideoDetail();
      return;
    }
    updateGsSwingVideoDetail({
      pgCode: gameNo,
      nasmoId: nasmoDetail.movNo,
      nasmoFile: nasmoDetail.fileNm,
      nasmoImgFile: nasmoDetail.imgNm,
      clubName: nasmoDetail.clubNm,
      distance: nasmoDetail.dist,
      distUnit: nasmoDetail.distUnit,
      holeNo: nasmoDetail.holeNo,
      regFeedNo: nasmoDetail.regFeedNo,
      analysisId: nasmoDetail.analysisId,
    });
  };

  const handleDeleteVideo = async () => {
    try {
      if (!videoDetail?.nasmoId) return;
      const result = await GSrecord.deleteNasmoClub({
        detailNo: videoDetail.nasmoId,
      });
      if (result.code === EnumRequestCode.FAILED) return;
      // Find deleted nasmo
      let courseIndexOfDeletedVideo = -1;
      let indexOfDeletedVideo = -1;
      for (let courseIndex = 0; courseIndex < dataGsSwingVideo.values.data.length; courseIndex++) {
        const deletedNasmoIndex = dataGsSwingVideo.values.data[courseIndex].swingVideo.findIndex(
          (nasmo) => nasmo.movNo === videoDetail.nasmoId,
        );
        if (deletedNasmoIndex > -1) {
          courseIndexOfDeletedVideo = courseIndex;
          indexOfDeletedVideo = deletedNasmoIndex;
          break;
        }
      }
      if (courseIndexOfDeletedVideo === -1 || indexOfDeletedVideo === -1) return;

      const newNasmoClubList = [...dataGsSwingVideo.values.data];
      newNasmoClubList[courseIndexOfDeletedVideo].swingVideo.splice(indexOfDeletedVideo, 1);
      if (newNasmoClubList[courseIndexOfDeletedVideo].swingVideo.length === 0) {
        chooseFirstVideo(newNasmoClubList);
      } else if (indexOfDeletedVideo > newNasmoClubList[courseIndexOfDeletedVideo].swingVideo.length - 1) {
        handleChooseVideo(
          newNasmoClubList[courseIndexOfDeletedVideo].swingVideo[0],
          newNasmoClubList[courseIndexOfDeletedVideo].gameNo,
        );
      } else {
        handleChooseVideo(
          newNasmoClubList[courseIndexOfDeletedVideo].swingVideo[indexOfDeletedVideo],
          newNasmoClubList[courseIndexOfDeletedVideo].gameNo,
        );
      }
      deleteFeedAfterDeletingSwingVideo(videoDetail?.regFeedNo);
    } catch (error: any) {
      console.warn(error);
      addNotification(translate('error.DELETE_SWING_VIDEO_FAILED'), NotificationType.DANGER);
    }
  };

  const chooseFirstVideo = (list: IGsNasmoClubResponse[]) => {
    if (!list || list.length === 0) return;
    for (let courseIndex = 0; courseIndex < list.length; courseIndex++) {
      if (list[courseIndex].swingVideo && list[courseIndex].swingVideo.length > 0) {
        handleChooseVideo(list[courseIndex].swingVideo[0], list[courseIndex].gameNo);
        break;
      }
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await getGsSwingVideo({ isRefresh: true });
    setIsRefreshing(false);
  };

  const goToRoundDetail = (pgCode: number) => {
    history.push(pathConstants.RECORD_DETAIL_GS(pgCode?.toString()));
  };

  const totalVideo = dataGsSwingVideo.values.data.reduce((prev, current) => prev + current.swingVideo?.length, 0);
  return (
    <>
      {!!videoDetail && (
        <VideoRegion
          videoDetail={{ ...videoDetail, clubCode: mappingClubCodeAndName[videoDetail?.clubName] }}
          className="sticky top-0 z-10"
          classSideWrapper="px-[24px]"
          hasAIAnalysis
          handleDelete={handleDeleteVideo}
          onPlayVideo={() => firebaseInstance.trackEvent(eventName.MENUCARD_ROUNDSV_PLAY, isMenucard)}
          onRequestAIClick={() => firebaseInstance.trackEvent(eventName.ACTIVITY_ROUNDS_VIDEO_REQUESTAI)}
          onViewAIResultClick={() => firebaseInstance.trackEvent(eventName.ACTIVITY_ROUNDS_VIDEO_VIEWAI)}
          recordType={EnumAIRecordType.GS}
        />
      )}
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={!!dataGsSwingVideo.loaded && dataGsSwingVideo.loaded > 0 && !isRefreshing}
        showFooter
      >
        <>
          {dataGsSwingVideo.loaded && dataGsSwingVideo.loaded > 0 && (!dataGsSwingVideo.values || totalVideo === 0) ? (
            <NotFoundItem
              image={noImageSwingFeedLight}
              className="pb-[80px]"
              text={translate('pages.record.message.NO_SWING_VIDEO')}
            />
          ) : (
            <InfiniteScrollWrapper
              loadMore={fetchData}
              isLastPage={dataGsSwingVideo.lastPage}
              loading={!!dataGsSwingVideo.loading || isRefreshing}
            >
              <div className="divide-y-bg-disable divide-y-[8px]">
                {dataGsSwingVideo.values.data.map((club, index) => (
                  <Fragment key={index}>
                    {club.swingVideo && club.swingVideo.length > 0 && (
                      <div className="pb-[16px]">
                        <div className="mt-[24px] px-[24px]">
                          <div className="flex items-center justify-between">
                            <div className="gz-text-md font-bold text-title">{club.ccName}</div>
                            <div
                              className="-mr-[8px] cursor-pointer px-[8px]"
                              onClick={() => {
                                // firebaseInstance.trackEvent(eventName.MENUCARD_ROUNDSV_OTHER, isMenucard);
                                goToRoundDetail(club.gameNo);
                              }}
                            >
                              <IconAngleRight className="stroke-text" />
                            </div>
                          </div>
                          <div className="gz-text-sm mt-[8px] flex divide-x divide-gray-20">
                            <div className="pr-[8px]">{DateTime.formatDate(club.timestart, translate)}</div>
                            <PlayMode
                              className="pl-[8px]"
                              playMode={club.playMode}
                              playModeSub={club.playModeSub}
                              playType={club.playType}
                              gameMode={club?.gameMode}
                            />
                          </div>
                        </div>
                        <div className="px-[24px]">
                          <NasmoList
                            defaultActive={videoIdDefault}
                            classWrapper="mt-[16px]"
                            classActiveHole="text-gz-primary"
                            listNasmo={club.swingVideo.map((nasmo) => {
                              return {
                                nasmoId: nasmo.movNo,
                                clubName: nasmo.clubNm,
                                clubCode: nasmo.clubCd,
                                distance: nasmo.dist,
                                distUnit: nasmo.distUnit,
                                nasmoImgFile: nasmo.imgNm,
                                holeNo: nasmo.holeNo,
                                analysisId: nasmo.analysisId,
                              };
                            })}
                            chooseVideo={(id: number) => {
                              // firebaseInstance.trackEvent(eventName.MENUCARD_ROUNDSV_OTHER, isMenucard);
                              handleChooseVideo(
                                club.swingVideo.find((nasmo) => nasmo.movNo === id),
                                club.gameNo,
                              );
                            }}
                            typeLayout={ITypeLayout.LIST}
                          />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </InfiniteScrollWrapper>
          )}
        </>
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  offsetListRecord: state.recordsReducer.offsetListRecord,
  dataGsSwingVideo: state.recordsReducer.dataGsSwingVideo,
});

const mapDispatchToProps = {
  getGsSwingVideo: recordsAction.getGsSwingVideo,
  updateGsSwingVideoDetail: recordsAction.updateGsSwingVideoDetail,
  setOffset: records.setOffset,
  addNotification: notificationActions.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordGsSwingVideo);
