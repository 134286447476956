export enum EnumBestPlayerNum {
  DRIVER_DISTANCE = 1,
  BATTING_AVERAGE = 2,
  GIR = 3,
  PUTTS = 4,
}

export enum EnumTopPlayerRank {
  GOLD = 1,
  SILVER = 2,
  BRONZE = 3,
}

export enum EnumTournamentProgress {
  IN_PROGRESS = '1',
  UPCOMMING = '2',
  ENDED = '3',
}

export enum EnumTournamentMode {
  STORE = '1',
  OPEN = '0',
}

export enum EnumTournamentLeaderboardMode {
  STROKE = '0',
  NEW_PERIO = '1',
}

export enum EnumTournamentLeaderboardOrder {
  SCORE = 'score',
  ROUND = 'round',
}

export enum EnumTournamentTopPlayerType {
  LONGEST = '1',
  NEAREST = '2',
  DISTANCE_DRIVER = '3',
  GIR = '4',
  PUTTS_PER_HOLE = '5',
}

export enum EnumTopTenPlayersType {
  BIRDIE = '-1',
  PAR = '0',
  BOGEY = '1',
  DOUBLE_PAR = '2',
}

export enum EnumTournamentRankingSex {
  ALL = 0,
  MALE = 1,
  FEMALE = 2,
}

export enum EnumEventType {
  TOURNAMENT = 'GLF',
  LEAGUE = 'LEAGUE',
}

export enum EnumStoreTournamentProgressFiltering {
  ALL = '0',
  IN_PROGRESS = '1',
  SCHEDULED_TO_PROCEED = '2',
  COMPLETE = '3',
}
