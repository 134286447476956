export enum EnumPlayType {
  GS = 'GS',
  GDR = 'GDR',
  TRAINING_MODE = 'TM',
}

export enum EnumRecordType {
  GS_ROUND = 'GS_ROUND',
  GS_SWING_VIDEO = 'GS_SWING_VIDEO',
  GDR_DRIVING = 'GDR_DRIVING',
  GDR_ROUND = 'GDR_ROUND',
  GDR_SWING_VIDEO = 'GDR_SWING_VIDEO',
  TM_DRIVING_RANGE = 'TM_DRIVING_RANGE',
  TM_APPROACH = 'TM_APPROACH',
  TM_PUTTING = 'TM_PUTTING',
  TM_SWING_VIDEO = 'TM_SWING_VIDEO',
}

export enum EnumPartnerPlayType {
  GENERAL = '0',
  TEAM = '1',
}

export enum EnumNumber {
  SIX = 6,
}

export enum EnumRecordAchievement {
  HOLE_IN_ONE = '1',
  ALBATROSSES = '2',
  ALL_EAGLE = '3-1',
  ALL_BIRDIE = '3-2',
  EVEN_PAR = '3-3',
  BEST_SCORE = '4',
  NO_BOGEY = '5',
  EAGLE_STREAK = '6-1',
  BIRDIE_STREAK = '6-2',
  PAR_STREAK = '6-3',
  EAGLE_MULTIPLE = '7-1',
  BIRDIE_MULTIPLE = '7-2',
  PAR_MULTIPLE = '7-3',
  LONG_PUTTS_METER = '10-1',
  LONG_PUTTS_YARD = '10-2',
}

export enum EnumTypeClub {
  DRIVER = 1,
  WOOD = 2,
  IRON = 3,
  UTILITY = 4,
  WEDGES = 5,
  PUTTER = 6,
}

export enum EnumTypeScoreCard {
  EAGLE = -2,
  BIRDIE = -1,
  BOGEY = 1,
  DOUBLE_BOGEY = 2,
}

export enum EnumTypeStablefordScoreCard {
  ALBATROSS = 8,
  EAGLE = 5,
  BIRDIE = 2,
  PAR = 0,
  BOGEY = -1,
  DOUBLE_BOGEY_AND_BELOW = -3,
}

export enum EnumAchievementCategory {
  EXCELLENT,
  GOOD,
}

export enum EnumPlayMode {
  STROKE = '0',
  MATCH = '1',
  SKINS = '2',
  STABLEFORD = '7',
  NEWPERIO = '8',
  LASVEGAS = '9',
  FOURSOMES = '10-1',
  BEST_SHOT_STROKE = '10-2',
  BEST_BALL_STROKE = '10-3',
  BEST_SHOT_MATCH = '10-4',
  BEST_BALL_MATCH = '10-5',
}

export enum EnumTee {
  WHITE = 0,
  BLACK = 1,
  RED = 2,
  BLUE = 3,
  YELLOW = 4,
  JUNIOR_B = 5,
  JUNIOR_A = 6,
}

export enum EnumGdrTee {
  LADY = '1',
  FRONT = '2',
  BACK_TEE = '3',
  CHAMPION = '4',
  SENIOR = '5',
}

export enum EnumRoundScorePlayType {
  NORMAL = 255,
  GLT = 0,
  GLF = 1,
  LGLT = 2,
  GCM = 3,
  GCF = 4,
  G_TOUR = 5,
  LG_TOUR = 6,
}

export enum EnumGreenStatus {
  SLIGHTLY_FAST = '0',
  VERY_FAST = '1',
  NORMAL = '2',
  SPEED = '3',
}

export enum EnumWindStatus {
  WEAKLY = '0',
  NORMAL = '1',
  STRONGLY = '2',
}

export enum EnumScoreCardMatchCode {
  DRAW = 0,
  UP = 1,
  DOWN = -1,
}

export enum EnumScoreCardMatch {
  AS = 'A/S',
  UP = 'UP',
  DOWN = 'DN',
}

export enum EnumTrainingModeType {
  DRIVING_RANGE = 'DRIVING_RANGE',
  APPROACH = 'APPROACH',
  PUTTING = 'PUTTING',
  SWING_VIDEO = 'SWING_VIDEO',
}

export enum EnumTrainingModeClubPath {
  IN_TO_OUT = 1,
  IN_TO_IN = 2,
  OUT_TO_IN = 3,
}

export enum EnumTrainingModeImpactPoint {
  TOE,
  LEFT,
  MIDDLE,
  RIGHT,
  HEEL,
}

export enum EnumTrainingModeSwingPosition {
  RIGHT = '1',
  LEFT = '2',
}

export enum EnumSocialGameModeType {
  CHALLENGES = 'CHALLENGE',
  STROKE = 'STROKE',
  TOURNAMENT = 'TOURNAMENT',
}

export enum EnumTeePosition {
  BLACK = 0,
  BLUE = 1,
  WHITE = 2,
  YELLOW = 3,
  RED = 4,
}

export enum EnumTeeHeight {
  h25mm = 25,
  h30mm = 30,
  h35mm = 35,
  h40mm = 40,
  h45mm = 45,
  h50mm = 50,
  h55mm = 55,
  h60mm = 60,
}

export enum EnumDifficulty {
  AMATEUR = 0,
  PRO = 1,
  BEGINNER = 2,
  G_TOUR = 3,
}

export enum EnumFavoriteClub {
  WOOD = 0,
  IRON = 1,
  WEDGE = 2,
}
