import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRecordScore } from 'src/models/record';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import { IOffsetListRecord, records, recordsAction } from 'src/store/records';
import { useScrollToOldPosition } from 'src/hooks/scroll-to-old';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import ScoreItem from 'src/view/pages/record/about/GS/list-score/ScoreItem';
import ListGroupMonth from 'src/view/pages/record/commons/ListGroupMonth';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';

interface IRecordGsRoundProps {
  offsetListRecord: IOffsetListRecord;
  setOffset: (offsetListRecord: IOffsetListRecord) => void;
  dataGsRecordScore: IObjectData<IRecordScore[][]>;
  getGsRound: (options: { isLoadingMore?: boolean; isRefresh?: boolean }) => Promise<void>;
}

function RecordGsRound(props: IRecordGsRoundProps): JSX.Element {
  const translate = useTranslation().t;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const firstLoad = props.dataGsRecordScore.loaded === 0;

  useScrollToOldPosition(!firstLoad, props.offsetListRecord.round, (value: number) => {
    props.setOffset({
      ...props.offsetListRecord,
      round: value,
    });
  });

  useEffect(() => {
    if (firstLoad) {
      fetchData();
    }
  }, []);

  const fetchData = (isLoadingMore?: boolean) => {
    props.getGsRound({ isLoadingMore });
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await props.getGsRound({ isRefresh: true });
    setIsRefreshing(false);
  };

  return (
    <>
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={!firstLoad && !props.dataGsRecordScore?.loading && !isRefreshing}
        showFooter
      >
        {firstLoad ? (
          <div className="flex items-center justify-center py-[24px]">
            <LoadingIndicator className="h-[16px] w-[16px] self-center" />
          </div>
        ) : (
          <>
            {props.dataGsRecordScore?.keySort?.length > 0 ? (
              <div className="px-[24px] pt-[24px]">
                <ListGroupMonth
                  dataObject={props.dataGsRecordScore}
                  fetchData={fetchData}
                  lastPage={props.dataGsRecordScore.lastPage}
                  loading={props.dataGsRecordScore.loading}
                  fieldDate="timestart"
                >
                  <ScoreItem />
                </ListGroupMonth>
              </div>
            ) : (
              <NotFoundItem className="pb-[80px]" text={translate('pages.record.message.NO_ROUND_RECORD')} />
            )}
          </>
        )}
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  offsetListRecord: state.recordsReducer.offsetListRecord,
  dataGsRecordScore: state.recordsReducer.dataGsRecordScore,
});

const mapDispatchToProps = {
  setOffset: records.setOffset,
  getGsRound: recordsAction.getGsRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordGsRound);
