import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useScrollToOldPosition } from 'src/hooks/scroll-to-old';
import { IGDRMiniRoundResponse } from 'src/requests/api/record/prop-state.type';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import { IOffsetListRecord, records, recordsAction } from 'src/store/records';
import ListGroupMonth from 'src/view/pages/record/commons/ListGroupMonth';
import ItemPractice from 'src/view/pages/record/about/GDR/practice-round/ItemPractice';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';

interface IRecordGdrPracticeRoundProps {
  offsetListRecord: IOffsetListRecord;
  setOffset: (offsetListRecord: IOffsetListRecord) => void;
  dataGdrPractice: IObjectData<IGDRMiniRoundResponse[][]>;
  getGdrPraticeRound: (options: { isLoadingMore?: boolean; isRefresh?: boolean }) => Promise<void>;
}

function RecordGdrPracticeRound(props: IRecordGdrPracticeRoundProps): JSX.Element {
  const translate = useTranslation().t;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const firstLoad = props.dataGdrPractice.loaded === 0;

  useScrollToOldPosition(!firstLoad, props.offsetListRecord.practice, (value: number) => {
    props.setOffset({
      ...props.offsetListRecord,
      practice: value,
    });
  });

  useEffect(() => {
    if (firstLoad) {
      fetchData();
    }
  }, []);

  const fetchData = (isLoadingMore?: boolean) => {
    props.getGdrPraticeRound({ isLoadingMore });
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await props.getGdrPraticeRound({ isRefresh: true });
    setIsRefreshing(false);
  };

  return (
    <>
      {/* // @TODO Can Rollback
        <div
          className="bg-bg py-[12px] px-[24px] gz-text-xs text-sub-body list-default text-center"
          dangerouslySetInnerHTML={{
            __html: translate('pages.record.type.GDR.practice_round.DESCRIPTION'),
          }}
        />
      */}
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={!!props.dataGdrPractice?.loaded && !firstLoad && !isRefreshing}
        showFooter
      >
        {firstLoad ? (
          <div className="flex items-center justify-center py-[24px]">
            <LoadingIndicator className="h-[16px] w-[16px] self-center" />
          </div>
        ) : (
          <>
            {props.dataGdrPractice?.keySort?.length > 0 ? (
              <div className="p-[24px]">
                <ListGroupMonth
                  dataObject={props.dataGdrPractice}
                  fetchData={fetchData}
                  lastPage={props.dataGdrPractice.lastPage}
                  loading={props.dataGdrPractice.loading}
                  fieldDate="tmTimeStart"
                >
                  <ItemPractice />
                </ListGroupMonth>
              </div>
            ) : (
              <NotFoundItem className="pb-[80px]" text={translate('pages.record.message.NO_ROUND_RECORD')} />
            )}
          </>
        )}
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  offsetListRecord: state.recordsReducer.offsetListRecord,
  dataGdrPractice: state.recordsReducer.dataGdrPractice,
});
const mapDispatchToProps = {
  setOffset: records.setOffset,
  getGdrPraticeRound: recordsAction.getGdrPraticeRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordGdrPracticeRound);
