import React from 'react';
import { constUnit } from 'src/constants/const';
import { fixDecimal } from 'src/services/utils';
import { useTranslation } from 'react-i18next';
import { INasmoDetail } from 'src/models/nasmo';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

interface IVideoDescriptionProps {
  videoDetail?: INasmoDetail;
  className?: string;
}
const VideoDescription: React.FC<IVideoDescriptionProps> = React.memo(({ videoDetail, className }) => {
  const translate = useTranslation().t;
  const userInfo = useSelector((state: any) => state.mainInfoReducer.userInfo);

  const shortDataList = [
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.DISTANCE'),
      value: videoDetail?.distance,
      render: `${fixDecimal(videoDetail?.distance)} ${videoDetail?.distUnit}`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.LAUNCH_ANGLE'),
      value: videoDetail?.ballAngle,
      render: `${fixDecimal(videoDetail?.ballAngle, false, 1)}${constUnit.DEGREE}`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.CARRY'),
      value: videoDetail?.carry,
      render: `${fixDecimal(videoDetail?.carry)} ${videoDetail?.distUnit}`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.LEFT_RIGHT_ANGLE'),
      value: videoDetail?.directionAngle,
      render: `${fixDecimal(videoDetail?.directionAngle, false, 1)}${constUnit.DEGREE}`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.LEFT_AND_RIGHT'),
      value: videoDetail?.ipDistanceOut,
      render: `${
        videoDetail?.ipDistanceOut != null
          ? Number(videoDetail?.ipDistanceOut) > 0
            ? translate('pages.record.direction_ball.RIGHT')
            : Number(videoDetail?.ipDistanceOut) < 0
            ? translate('pages.record.direction_ball.LEFT')
            : translate('pages.record.direction_ball.CENTER')
          : videoDetail?.ipDistanceOut
      } ${
        videoDetail?.ipDistanceOut != null
          ? fixDecimal(Math.abs(Number(videoDetail?.ipDistanceOut)))
          : videoDetail?.ipDistanceOut
      }`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.BACKSPIN'),
      value: videoDetail?.spinBack,
      render: `${videoDetail?.spinBack}`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.BALL_SPEED'),
      value: videoDetail?.ballSpeed,
      render: `${fixDecimal(videoDetail?.ballSpeed)} ${userInfo?.speedUnit}`,
    },
    {
      title: translate('pages.record.type.GDR.swing_video.short_data.SIDE_SPIN'),
      value: videoDetail?.spinSide,
      render: `${videoDetail?.spinSide}`,
    },
  ];

  return (
    <div className={twMerge('relative bg-gz-white p-[24px] pt-0', className)}>
      <div className="grid grid-cols-2 gap-x-[12px] gap-y-[16px] rounded-b-[16px] px-[16px] py-[10px] shadow-video-des md:gap-x-[24px]">
        {shortDataList.map((shortData, index) => (
          <div key={index} className="flex justify-between">
            <div className="gz-text-xsm text-sub-body">{shortData.title}</div>
            {shortData.value != null && <div className="gz-text-sm text-right font-bold">{shortData.render}</div>}
          </div>
        ))}
      </div>
    </div>
  );
});

export default VideoDescription;
