import { useTranslation } from 'react-i18next';
import { EnumPlayDifficultyType, EnumRoundScorePlayType, EnumSystemCode } from 'src/constants/enum';
import { IPartner } from 'src/models/record';
import SystemVersion from 'src/view/commons/customs/SystemVersion';
import PartnerList from 'src/view/pages/record/commons/PartnerList';
import PlayDifficulty from 'src/view/pages/record/commons/PlayDifficult';
import PlayMode from 'src/view/pages/record/commons/PlayMode';
import RecordAchievement from 'src/view/pages/record/commons/RecordAchievement';
import RoundScore from 'src/view/pages/record/commons/RoundScore';
interface IRecordScoreInfo {
  ccName: string;
  playDifficulty?: EnumPlayDifficultyType;
  playMode: number;
  playModeSub?: number;
  playType?: EnumRoundScorePlayType;
  systemVersion?: EnumSystemCode;
  score: number;
  holeCnt?: number;
  totalHoleCnt: number;
  partnerList?: IPartner[];
  partnerCnt?: number;
  tags: string;
  tagsCnt: string;
  totalScore: number;
  matchTotal: number;
  gameMode?: string;
}

interface IRoundRecordInformationProps {
  recordScore: IRecordScoreInfo;
  onClick?: () => void;
}

function RoundRecordInformation({ recordScore, onClick }: IRoundRecordInformationProps): JSX.Element {
  const translate = useTranslation().t;
  return (
    <div className="cursor-pointer rounded-[16px] p-[18px] shadow-xsm" onClick={onClick}>
      <div className="flex justify-between">
        <div>
          <div className="mr-[8px]">
            <span className="gz-text-xs align-middle font-bold [&:not(:last-child)]:mr-[8px]">
              {recordScore.ccName}
            </span>
            {recordScore.playDifficulty != null && (
              <div className="inline-flex flex-shrink-0 align-middle [&:not(:last-child)]:mr-[4px]">
                <PlayDifficulty difficulty={recordScore.playDifficulty} />
              </div>
            )}
            <SystemVersion type={recordScore.systemVersion} className="inline-flex flex-shrink-0 align-middle" />
          </div>
          <div className="gz-text-xsm mt-[4px] font-light text-sub-body">
            <PlayMode
              className="[&:not(:last-child)]:after:mx-[4px] [&:not(:last-child)]:after:content-['•']"
              playMode={recordScore.playMode}
              playModeSub={recordScore.playModeSub}
              playType={recordScore.playType}
              gameMode={recordScore?.gameMode}
            />
            <span>
              {translate('pages.record.information.TOTAL_HOLE_COUNT', {
                count: recordScore.holeCnt,
              })}
            </span>
          </div>
        </div>
        <RoundScore
          playMode={recordScore.playMode}
          playModeSub={recordScore.playModeSub}
          hitCnt={recordScore.totalScore}
          holeCnt={recordScore.holeCnt}
          totalScore={recordScore.score}
          matchTotal={recordScore.matchTotal}
        />
      </div>
      {recordScore.partnerList && recordScore.partnerCnt ? (
        <PartnerList partnerList={recordScore.partnerList} partnerCnt={recordScore.partnerCnt} />
      ) : (
        <></>
      )}
      <RecordAchievement recordTags={recordScore.tags} recordTagsCnt={recordScore.tagsCnt} />
    </div>
  );
}

export default RoundRecordInformation;
