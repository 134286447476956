export const CT_ENV_SITE = {
  develop: 'development',
  production: 'production',
};

export const CT_ENV_FLAVOR = process.env.REACT_APP_FLAVOR ?? '';

export const CT_BASE_URL = process.env.REACT_APP_API_URL ?? '';
export const GSM_BASE_URL = process.env.REACT_APP_GSM_URL ?? '';
export const GSM_AUTH_BASE_URL = process.env.REACT_APP_GSM_AUTH_URL ?? '';
export const IAB_BASE_URL = process.env.REACT_APP_IAB_URL ?? '';

export const CT_HASH_INFO = process.env.REACT_APP_KEY_SUM_HASH ?? '';
export const CT_RECAPTCHA_KEY = process.env.REACT_APP_KEY_RECAPTCHA ?? '';

export const CT_GOOGLEMAP_API = {
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
};

export const CT_GOOGLE_INFO = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
};

export const CT_FACEBOOK_INFO = {
  AppID: process.env.REACT_APP_FACEBOOK_APPID ?? '',
};

export const CT_APPLE_INFO = {
  signIn_clientId: process.env.REACT_APP_APPLE_SIGN_IN_CLIENT_ID ?? '',
  signIn_redirectLoginURI: process.env.REACT_APP_APPLE_SIGN_IN_REDIRECT_URI_LOGIN ?? '',
  signIn_redirectConnectURI: process.env.REACT_APP_APPLE_SIGN_IN_REDIRECT_URI_SETTING ?? '',
};

export const CT_WECHAT_INFO = {
  AppID: process.env.REACT_APP_WECHAT_APPID ?? '',
  AppSecret: process.env.REACT_APP_WECHAT_APPSECRET ?? '',
};

export const CT_LINE_INFO = {
  appID: process.env.REACT_APP_LINE_APPID || '',
  appSecret: process.env.REACT_APP_LINE_APPSECRET || '',
  redirectLoginURI: process.env.REACT_APP_LINE_REDIRECT_URI_LOGIN || '',
  redirectSettingURI: process.env.REACT_APP_LINE_REDIRECT_URI_SETTING || '',
};

export const CT_SCREEN_LOGIN = {
  gdr: process.env.REACT_APP_GDR_API_URL ?? '',
  gs: process.env.REACT_APP_GS_SOCKET_API_URL ?? '',
};

export const CT_FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
