import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { NotificationType } from 'src/models';
import { signupMiddleware } from 'src/requests/api/account/signup';
import { notificationActions } from 'src/store/notification';
import { findPasswordMiddleware } from 'src/requests/api/account/find-password';
import { InputStatus } from 'src/view/commons/elements/Input';
import Button from 'src/view/commons/elements/Button';
import { ISendEmailEntitiesResponse } from 'src/requests/api/account/prop-state.type';
import { EnumRequestCode, EnumStatusCode } from 'src/constants/enum';
import { verifyUserEmail } from 'src/services/verify-user-information';
import { EnumFireStoreCollectionName, EnumTypeApiSendMail } from 'src/constants/enum/global.enum';
import { firebaseInstance } from 'src/init-firebase';

interface IInputEmailProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  addNotification: (message: string, type: NotificationType) => void;
  containerClassName?: string;
  classNameSendButton?: string;
  colorSendButton?: 'secondary';
  className?: string;
  usrNo?: string;
  needCheckEmailExist?: boolean;
  typeApiSendMail?: EnumTypeApiSendMail;
  message?: string | JSX.Element;
  errorMessage?: string | JSX.Element;
  doneMessage?: string | JSX.Element;
  status?: InputStatus;
  disableVerifyBtn?: boolean;
  onSendVerifyCode?: (isResent: boolean) => void;
  onSendEmailSuccess?: (entities: any, token?: string) => void;
  setError?: (error?: string) => void;
}

function InputEmail({
  addNotification,
  setError,
  typeApiSendMail,
  colorSendButton,
  containerClassName,
  className,
  classNameSendButton,
  usrNo,
  needCheckEmailExist,
  message,
  errorMessage,
  doneMessage,
  status = InputStatus.Normal,
  disableVerifyBtn,
  onSendVerifyCode,
  onSendEmailSuccess,
  ...rest
}: IInputEmailProps): JSX.Element {
  const translate = useTranslation().t;
  const [isLoading, setIsLoading] = useState(false);
  const sendEmailEntitiesResponse = useRef<ISendEmailEntitiesResponse | null>(null);

  const borderColor = (): string => {
    if (status === InputStatus.Error) return 'border-gz-danger';
    if (status === InputStatus.Done) return 'border-primary-75';
    return 'border-gray-20';
  };

  const textColor = (): string => {
    if (status === InputStatus.Error) return 'text-gz-danger';
    if (status === InputStatus.Done) return 'text-primary-75';
    return 'text-text';
  };

  const messageElement = (): JSX.Element => {
    let text;
    if (status === InputStatus.Done) {
      text = doneMessage;
    } else if (status === InputStatus.Error) {
      text = errorMessage;
    } else {
      text = message;
    }
    if (text && typeof text === 'string') return <div className={twMerge('gz-text-xsm mt-2', textColor())}>{text}</div>;
    if (text) return <>{text}</>;
    return <></>;
  };

  const checkUserEmail = async () => {
    const result = await findPasswordMiddleware.checkUserEmail({
      email: rest.value as string,
      usrNo: usrNo as string,
    });
    if (result.code === EnumRequestCode.SUCCESS && result.entities.usrYn) {
      return result.entities.token;
    }
    return null;
  };

  const sendEmail = async (token?: string, error?: string) => {
    let func;
    let errorStr = error ?? '';
    errorStr += '-----sendEmail-Start---';
    switch (typeApiSendMail) {
      case EnumTypeApiSendMail.SIGNUP:
        errorStr += '-----sendEmail-sendCodeVerifyEmail---';
        func = signupMiddleware.sendCodeVerifyEmail;
        break;
      case EnumTypeApiSendMail.UPDATE_SMSAUTHYN:
        errorStr += '-----sendEmail-sendFirstEmailCode---';
        func = signupMiddleware.sendFirstEmailCode;
        break;
      default:
        errorStr += '-----sendEmail-sendVerifyEmail---';
        func = findPasswordMiddleware.sendVerifyEmail;
        break;
    }
    errorStr += '-----sendEmail-request-API---';
    const result = await func({
      email: rest.value as string,
      sendCnt: sendEmailEntitiesResponse.current ? parseInt(sendEmailEntitiesResponse.current.sendCnt) : 0,
    });
    errorStr += '-----sendEmail-request-API-done---';
    if (result.code === EnumRequestCode.SUCCESS) {
      errorStr += '-----sendEmail-onSendEmailSuccess---';
      sendEmailEntitiesResponse.current = result.entities;
      onSendEmailSuccess && onSendEmailSuccess(result.entities, token);
    } else if (result.code === EnumStatusCode.FAILED.toString()) {
      errorStr += '-----sendEmail-Failed-1---';
      addNotification(translate('error.PHONE_NUMBER_iS_INVALID'), NotificationType.DANGER);
    } else if (result.code === EnumRequestCode.FAILED.toString() && !result?.entities?.isAvailable) {
      errorStr += '-----sendEmail-Failed-2---';
      setError && setError('emailExist');
    } else {
      errorStr += '-----sendEmail-Failed-3---';
      addNotification(translate('error.SEND_SMS_VERIFY_CODE_FAILED'), NotificationType.DANGER);
    }
    return errorStr;
  };

  const sendVerifyCode = async () => {
    let errorStr = '-----sendVerifyCode-Start---';
    setIsLoading(true);
    onSendVerifyCode?.call(null, status === InputStatus.Done);
    try {
      let token;
      if (usrNo) {
        token = await checkUserEmail();
      }
      if (usrNo && !token) {
        addNotification(translate('error.EMAIL_NOT_MATCH_USRNO'), NotificationType.DANGER);
        return;
      }
      if (needCheckEmailExist) {
        const result = await verifyUserEmail(rest.value as string);
        if (!result && setError) {
          setError('emailExist');
          return;
        }
      }
      const err = await sendEmail(token);
      errorStr += err;
    } catch (err: any) {
      console.warn(err);
      errorStr += '-----sendVerifyCode-Failed---';
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    } finally {
      setIsLoading(false);
      errorStr += '-----sendVerifyCode-End---';
      firebaseInstance.logCrashlytics(errorStr, EnumFireStoreCollectionName.CRASHLYITC_TRACES);
    }
  };

  return (
    <div className={twMerge('flex w-full flex-col', containerClassName)}>
      <div className={twMerge('flex')}>
        <div
          className={twMerge(
            'flex min-w-0 flex-auto items-center rounded-[8px] border-[1px] border-solid',
            'h-[3rem] py-2 px-3 transition-colors',
            borderColor(),
            status === InputStatus.Normal ? 'focus-within:border-primary-75' : '',
            className ?? '',
          )}
        >
          <input {...rest} className="fill-vailable-mw flex-auto" />
        </div>
        <Button
          className={twMerge(
            classNameSendButton ||
              'text-md gz-text-xs ml-1.5 h-[3rem] w-[6rem] rounded-[6px] bg-secondary-20 leading-[0] text-gz-secondary',
          )}
          disabled={status === InputStatus.Error || !rest.value || rest.disabled || disableVerifyBtn}
          loading={isLoading}
          color={colorSendButton}
          onClick={sendVerifyCode}
        >
          <div className="gz-text-xs">
            {translate(
              status === InputStatus.Done
                ? 'commons.customs.input_phone_number.RESEND_BTN'
                : 'commons.customs.input_phone_number.SEND_BTN',
            )}
          </div>
        </Button>
      </div>
      <div>{messageElement()}</div>
    </div>
  );
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(null, mapDispatchToProps)(InputEmail);
