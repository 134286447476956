import { useTranslation } from 'react-i18next';
import { MAX_PARTNER_IMAGES } from 'src/constants/const';
import { IPartner } from 'src/models/record';
import ImageGroup from 'src/view/commons/customs/ImageGroup';

interface IPartnerListProps {
  partnerList: IPartner[];
  partnerCnt: number;
}

function PartnerList({ partnerList = [], partnerCnt = 0 }: IPartnerListProps) {
  const translate = useTranslation().t;

  if (partnerCnt === 0) return <></>;

  const displayedImageCount = partnerCnt > MAX_PARTNER_IMAGES ? MAX_PARTNER_IMAGES - 1 : MAX_PARTNER_IMAGES;
  const displayedPartnerList = partnerList.slice(0, displayedImageCount);

  return (
    <div className="mt-[16px] flex items-start">
      <div className="flex-shrink-0">
        <ImageGroup
          images={partnerList.map((partner) => partner.usrNickImage)}
          count={partnerCnt}
          maxImage={MAX_PARTNER_IMAGES}
        />
      </div>
      <div className="ml-[4px] text-sub-body">
        <span className="gz-text-tiny font-bold">
          {displayedPartnerList.map((partner) => partner.usrNickname).join(', ')}
        </span>
        <span className="gz-text-xsm ml-[4px]">
          {partnerCnt > displayedPartnerList.length
            ? translate('pages.record.information.PLAY_WITH_OTHERS', {
                count: partnerCnt - displayedPartnerList.length,
              })
            : translate('pages.record.information.PLAY_WITH_PARTNERS')}
        </span>
      </div>
    </div>
  );
}

export default PartnerList;
