import React, { useState } from 'react';
import { IconInfoCircle, IconClose } from 'src/assets/svg';
import { twMerge } from 'tailwind-merge';

interface INoteTooltipProps {
  className?: string;
  classTitle?: string;
  classIconNote?: string;
  classContentNote?: string;
  title: string;
  content: React.ReactNode;
}

const NoteTooltip: React.FC<INoteTooltipProps> = React.memo(
  ({ className, classTitle, classIconNote, classContentNote, title, content }) => {
    const [toggleNote, setToggleNote] = useState(false);
    return (
      <div className={twMerge('relative flex items-center', className)}>
        <div className={twMerge('font-bold dark:text-gz-white', classTitle)}>{title}</div>
        <span className="cursor-pointer" onClick={() => setToggleNote(!toggleNote)}>
          <IconInfoCircle className={twMerge('dark:stroke-gz-white', classIconNote)} />
        </span>
        <div
          className={twMerge(
            'absolute top-7 left-0 z-10 w-[244px] rounded-[16px] p-[20px]',
            'gz-text-sm flex-row flex-nowrap shadow-xsm',
            'bg-gz-white text-sub-body dark:border-dark-33 dark:bg-dark-16 dark:text-gz-white',
            toggleNote ? 'flex' : 'hidden',
            classContentNote,
          )}
        >
          {content}
          <div className="shrink-0 cursor-pointer" onClick={() => setToggleNote(false)}>
            <IconClose className="stroke-title dark:stroke-gz-white" />
          </div>
        </div>
      </div>
    );
  },
);

export default NoteTooltip;
