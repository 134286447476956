import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ITmDrivingRangeResponse } from 'src/requests/api/record/prop-state.type';
import ListGroupMonth from 'src/view/pages/record/about/training-mode/commons/ListGroupMonth';
import { IOffsetListRecord, ITmValues, records, recordsAction } from 'src/store/records';
import { useScrollToOldPosition } from 'src/hooks/scroll-to-old';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';
import DrivingRangeItem from 'src/view/pages/record/about/training-mode/driving-range/DrivingRangeItem';
import MonthPicker from 'src/view/commons/customs/MonthPicker';
import dayjs from 'dayjs';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import { EnumTrainingModeType } from 'src/constants/enum';
import NoTrainingModeData from 'src/view/pages/record/about/training-mode/commons/NoData';

interface IProps {
  offsetListRecord: IOffsetListRecord;
  setOffset: (offsetListRecord: IOffsetListRecord) => void;
  dataTmDrivingRange: ITmValues<ITmDrivingRangeResponse[]>;
  getTmDrivingRangeApproachPutting: (options: {
    type: EnumTrainingModeType;
    date?: dayjs.Dayjs;
    isRefresh?: boolean;
  }) => Promise<void>;
}

function RecordTrainingModeDrivingRange(props: IProps): JSX.Element {
  const [isRefreshing, setIsRefreshing] = useState(false);

  useScrollToOldPosition(props.dataTmDrivingRange.loaded, props.offsetListRecord?.tmDrivingRange, (value: number) => {
    props.setOffset({
      ...props.offsetListRecord,
      tmDrivingRange: value,
    });
  });

  useEffect(() => {
    if (props.dataTmDrivingRange.loaded) return;
    props.getTmDrivingRangeApproachPutting({ type: EnumTrainingModeType.DRIVING_RANGE });
  }, []);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await props.getTmDrivingRangeApproachPutting({
      type: EnumTrainingModeType.DRIVING_RANGE,
      date: props.dataTmDrivingRange.date,
      isRefresh: true,
    });
    setIsRefreshing(false);
  };

  const handleChange = (date: dayjs.Dayjs) => {
    props.getTmDrivingRangeApproachPutting({ type: EnumTrainingModeType.DRIVING_RANGE, date });
  };

  const children = useMemo(() => <DrivingRangeItem />, []);
  return (
    <>
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={!props.dataTmDrivingRange.loading && !isRefreshing}
        showFooter
      >
        <>
          {props.dataTmDrivingRange.loaded && (
            <MonthPicker
              date={props.dataTmDrivingRange.date}
              months={props.dataTmDrivingRange.months}
              onChange={handleChange}
            />
          )}

          {props.dataTmDrivingRange.loading && !isRefreshing ? (
            <div className="flex justify-center py-[24px]">
              <LoadingIndicator className="h-[16px] w-[16px]" />
            </div>
          ) : (
            <>
              {props.dataTmDrivingRange.data.length > 0 ? (
                <div className="p-[24px]">
                  <ListGroupMonth dataObject={props.dataTmDrivingRange.data}>{children}</ListGroupMonth>
                </div>
              ) : (
                <NoTrainingModeData />
              )}
            </>
          )}
        </>
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  dataTmDrivingRange: state.recordsReducer.dataTmDrivingRange,
  offsetListRecord: state.recordsReducer.offsetListRecord,
});

const mapDispatchToProps = {
  setOffset: records.setOffset,
  getTmDrivingRangeApproachPutting: recordsAction.getTmDrivingRangeApproachPutting,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordTrainingModeDrivingRange);
