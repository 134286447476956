import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  classContainer?: string;
  children?: JSX.Element;
  zIndex?: number;
  staticMode?: boolean;
  onClose?: () => void;
  isOpen?: boolean;
  bgClassName?: string;
}

function Modal({
  className,
  classContainer,
  children,
  zIndex,
  staticMode = false,
  onClose,
  isOpen,
  bgClassName,
}: Props): JSX.Element {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Transition appear show={isOpen} style={{ zIndex: zIndex || 99 }}>
      <Dialog
        static={staticMode}
        className="relative"
        open={isOpen}
        onClose={() => !staticMode && handleClose()}
        style={{ zIndex: zIndex || 99 }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={twMerge('fixed inset-0 bg-black bg-opacity-25', bgClassName)} />
        </Transition.Child>
        <div className={twMerge('fixed inset-0', classContainer)}>
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  'w-full max-w-md transform overflow-hidden bg-white md:rounded-[6px]',
                  'text-left align-middle shadow-xl transition-all',
                  className,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
