import { colors } from 'src/constants/const/color.constants';

export const MAX_PARTNER_IMAGES = 3;
export const MAX_SMALL_HOLE_COUNT = 9;
export const MAX_STANDARD_HOLE_COUNT = 18;

export const groupShotInfo = [
  {
    color: colors.transparent,
    backgroundColor: colors.green,
    label: 'pages.record.detail.driver_hit_group.GROUP_SHOT_TYPE_1',
    attrs: [0],
  },
  {
    color: colors.txt_black,
    backgroundColor: colors.green,
    label: 'pages.record.detail.driver_hit_group.GROUP_SHOT_TYPE_2',
    attrs: [1],
  },
  {
    color: colors.danger,
    backgroundColor: colors.white,
    label: 'pages.record.detail.driver_hit_group.GROUP_SHOT_TYPE_3',
    attrs: [3],
  },
  {
    color: colors.secondary_75,
    backgroundColor: colors.white,
    label: 'pages.record.detail.driver_hit_group.GROUP_SHOT_TYPE_4',
    attrs: [4],
  },
  {
    color: colors.orange_75,
    backgroundColor: colors.white,
    label: 'pages.record.detail.driver_hit_group.GROUP_SHOT_TYPE_5',
    attrs: [2],
  },
  {
    color: colors.txt_black,
    backgroundColor: colors.white,
    label: 'pages.record.detail.driver_hit_group.GROUP_SHOT_TYPE_6',
    attrs: [],
  },
];

export const TM_ALL_FILTER = 'All';
