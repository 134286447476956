import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { history } from 'src/services/history';
import {
  IconAIAnalysisArrowLeft,
  IconAIAnalysisArrowRight,
  IconClose,
  iconGolfFlag,
  iconGolfTraining,
  iconGolfUser,
} from 'src/assets/svg';
import {
  formatDate,
  LOCAL_STORAGE_AI_ANALYSIS_LAST_OPEN_TIME,
  LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL,
  pathConstants,
} from 'src/constants/const';
import { EnumPlayType, EnumRecordType } from 'src/constants/enum';
import DraggableScrollView from 'src/view/commons/customs/DraggableScrollView';
import useScroll from 'src/hooks/use-scroll';
import { useLocation } from 'react-router-dom';
import { getLocalStorage, getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import { eventName } from 'src/constants/const/firebase.constants';
import HeaderTabsCustom from 'src/view/commons/customs/HeaderTabsCustom';
import { useModal } from 'src/hooks/use-modal';
import { Transition } from '@headlessui/react';
import dayjs from 'dayjs';

function RecordListHeader(): JSX.Element {
  const translate = useTranslation().t;
  const [showNewLabel, setShowNewLabel] = useState(!getLocalStorage(LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL));
  const { pathname } = useLocation();
  const { scrollY } = useScroll();
  const { isOpen, onOpen, onClose } = useModal(false);
  const [hasAINotice, setHasAINotice] = useState(false);
  const isSwingVideoPaths = [
    pathConstants.RECORDS_GS_SWING,
    pathConstants.RECORDS_GDR_SWING,
    pathConstants.RECORDS_TRAINING_MODE_SWING,
  ].includes(pathname);

  const typeRound = useMemo(() => {
    if (pathname.startsWith(pathConstants.RECORDS_GDR)) return EnumPlayType.GDR;
    if (pathname.startsWith(pathConstants.RECORDS_TRAINING_MODE)) return EnumPlayType.TRAINING_MODE;
    return EnumPlayType.GS;
  }, [pathname]);

  useEffect(() => {
    if (typeRound === EnumPlayType.TRAINING_MODE) {
      setShowNewLabel(false);
      saveObjectLocalStorage(LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL, true);
    }
  }, [typeRound]);

  useEffect(() => {
    handleShowAiAnalysisNotice();
  }, [pathname]);

  const handleShowAiAnalysisNotice = () => {
    onOpen();
    if (isSwingVideoPaths) {
      setHasAINotice(false);
      return;
    }
    const lastOpenData = getObjectLocalStorage(LOCAL_STORAGE_AI_ANALYSIS_LAST_OPEN_TIME);
    setHasAINotice(!lastOpenData[typeRound] || lastOpenData[typeRound] !== dayjs().format(formatDate.API_DATETIME));
  };

  // Hide 1 day after closing
  const handleCloseAIAnalysis = () => {
    onClose();
    const lastOpenData = getObjectLocalStorage(LOCAL_STORAGE_AI_ANALYSIS_LAST_OPEN_TIME);
    lastOpenData[typeRound] = dayjs().format(formatDate.API_DATETIME);
    saveObjectLocalStorage(LOCAL_STORAGE_AI_ANALYSIS_LAST_OPEN_TIME, lastOpenData);
  };

  const pages = [
    {
      title: translate('pages.record.ROUND'),
      path: pathConstants.RECORDS_GS_ROUND,
      icon: iconGolfFlag,
      type: EnumPlayType.GS,
      eventName: eventName.ACTIVITY_ROUNDS,
      tabs: [
        {
          title: translate('pages.record.type.GS.score.TITLE'),
          path: pathConstants.RECORDS_GS_ROUND,
          type: EnumRecordType.GS_ROUND,
        },
        {
          title: translate('pages.record.type.GS.swing_video.TITLE'),
          path: pathConstants.RECORDS_GS_SWING,
          type: EnumRecordType.GS_SWING_VIDEO,
        },
      ],
    },
    {
      title: translate('pages.record.PRACTICE'),
      path: pathConstants.RECORDS_GDR,
      icon: iconGolfUser,
      type: EnumPlayType.GDR,
      eventName: eventName.ACTIVITY_GDR,
      tabs: [
        {
          title: translate('pages.record.type.GDR.driving_range.TITLE'),
          path: pathConstants.RECORDS_GDR,
          type: EnumRecordType.GDR_DRIVING,
        },
        {
          title: translate('pages.record.type.GDR.practice_round.TITLE'),
          path: pathConstants.RECORDS_GDR_PRACTICE,
          type: EnumRecordType.GDR_ROUND,
        },
        {
          title: translate('pages.record.type.GDR.swing_video.TITLE'),
          path: pathConstants.RECORDS_GDR_SWING,
          type: EnumRecordType.GDR_SWING_VIDEO,
        },
      ],
    },
    {
      title: (
        <div className="flex items-center">
          <div>{translate('pages.record.TRAINING_MODE')}</div>
          {typeRound !== EnumPlayType.TRAINING_MODE && showNewLabel && (
            <div className="gz-text-xtiny ml-[4px] rounded-[10px] bg-gz-danger p-[2px_4px] text-gz-white">
              {translate('pages.record.type.training_mode.NEW_LABEL')}
            </div>
          )}
        </div>
      ),
      path: pathConstants.RECORDS_TRAINING_MODE,
      icon: iconGolfTraining,
      type: EnumPlayType.TRAINING_MODE,
      eventName: eventName.ACTIVITY_MYPRACTICE,
      tabs: [
        {
          title: translate('pages.record.type.training_mode.driving_range.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE,
          type: EnumRecordType.TM_DRIVING_RANGE,
        },
        {
          title: translate('pages.record.type.training_mode.approach.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE_APPROACH,
          type: EnumRecordType.TM_APPROACH,
        },
        {
          title: translate('pages.record.type.training_mode.putting.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE_PUTTING,
          type: EnumRecordType.TM_PUTTING,
        },
        {
          title: translate('pages.record.type.training_mode.swing_video.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE_SWING,
          type: EnumRecordType.TM_SWING_VIDEO,
        },
      ],
    },
  ];

  return (
    <>
      <HeaderTabsCustom
        classContainer="border-b-[1px] border-gray-20"
        className="shadow-none"
        tabs={pages.map((page) => ({
          title: page.title,
          path: page.path,
          eventName: page.eventName,
          startWith: true,
        }))}
      />
      <div className={twMerge('sticky top-[48px] z-[10] bg-gz-white transition-all', scrollY >= 1 && 'shadow-bottom')}>
        <DraggableScrollView sticky className="scroll-hidden flex py-[12px]">
          {pages.map((page) => (
            <Fragment key={page.path}>
              {typeRound === page.type && (
                <>
                  {page.tabs.map((tab) => (
                    <div className="mr-[8px] w-fit shrink-0 first:ml-[16px] last:mr-[16px]" key={tab.title}>
                      <div className="relative flex shrink-0">
                        <button
                          className={twMerge(
                            'flex-shrink-0 cursor-pointer rounded-[30px] font-bold focus:!outline-none',
                            'gz-text-sm h-fit shrink-0 px-[16px] py-[8px]',
                            pathname === tab.path ? 'bg-primary-75 text-gz-white ' : 'bg-primary-20 text-primary-75',
                          )}
                          onClick={() => history.replace(tab.path)}
                        >
                          {tab.title}
                        </button>
                        {[
                          pathConstants.RECORDS_GS_SWING,
                          pathConstants.RECORDS_GDR_SWING,
                          pathConstants.RECORDS_TRAINING_MODE_SWING,
                        ].includes(tab.path) &&
                          hasAINotice &&
                          isOpen && (
                            <>
                              {pathConstants.RECORDS_GS_SWING === tab.path ? (
                                <IconAIAnalysisArrowLeft className="pointer-events-none absolute left-full top-[16px]" />
                              ) : (
                                <IconAIAnalysisArrowRight className="pointer-events-none absolute top-[16px] right-full" />
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Fragment>
          ))}
        </DraggableScrollView>

        {hasAINotice && (
          <Transition
            show={isOpen}
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className="mx-[16px]"
          >
            <div>
              <div className="flex bg-semantic-yellow-30 p-[12px_16px]">
                <div className="mr-[8px]">
                  <span className="gz-text-tiny rounded-[4px] bg-gz-white/80 p-[1px_4px]">
                    {translate('pages.record.BETA')}
                  </span>
                  <span className="gz-text-sm ml-[8px] font-bold">
                    {translate('pages.record.ANALYSIS_YOUR_VIDEO_WITH_AI_NOW')}
                  </span>
                </div>
                <IconClose className="ml-auto shrink-0 cursor-pointer stroke-text" onClick={handleCloseAIAnalysis} />
              </div>
            </div>
          </Transition>
        )}
        {isSwingVideoPaths && (
          <div className="bg-light-gray-30 p-[8px_16px]">
            <span className="gz-text-tiny rounded-[4px] bg-semantic-yellow-30 p-[1px_4px]">
              {translate('pages.record.BETA')}
            </span>
            <span className="gz-text-sm ml-[8px]"> {translate('pages.record.AI_FOR_RIGHT_HANDED')}.</span>
          </div>
        )}
      </div>
    </>
  );
}

export default RecordListHeader;
